@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');

:root {
  --primary: #a30e36;
  --primary-dark: #1b0009;
  --secondary: hsl(0, 66%, 54%);
  --white: hsl(0, 0%, 100%);
  --gray: hsl(210, 2%, 62%);
  --gold: #eaa92e;
  --blue: #1a73e8;
  --green: #01875f;
  --linear-background: linear-gradient(121deg, #a30e36 0%, #1b0009 100%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Teko', sans-serif;

}

a {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none;
  border: none;
}